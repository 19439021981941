import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import normalizeMenu from './normalizeMenu';

const MenuNL = () => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(name: { eq: "top-nl" }) {
        name
        menuItems {
          nodes {
            label
            url
            databaseId
            parentDatabaseId
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                }
              }
            }
          }
        }
      }
    }
  `);

  if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null;
  const [parents] = normalizeMenu(wpMenu.menuItems.nodes);

  return <>{parents}</>;
};

export default MenuNL;
