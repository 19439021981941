import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'gatsby-plugin-transition-link';

import { siteMetadata } from '@siteconfig';
import logoNL from '@content/assets/img/logo_nl.svg';
import logoEN from '@content/assets/img/logo_en.svg';

const Logo = ({ langcode }) => {
  const { t } = useTranslation();
  const { languages } = siteMetadata;

  let homePath = '/';
  const logo = langcode === 'NL' ? logoNL : logoEN;

  languages.forEach((lang) => {
    if (lang.code === langcode && !lang.default) {
      homePath = `/${langcode.toLowerCase()}`;
    }
  });

  return (
    <div className="logo">
      <Link
        exit={{
          length: 0.5,
        }}
        entry={{
          length: 0.5,
        }}
        to={homePath}
      >
        <img src={logo} alt={t('siteMeta.title')} height="auto" width="auto" />
      </Link>
    </div>
  );
};

export default Logo;
