module.exports = {
  siteMetadata: {
    /* Translations for the site. For translated of strings/content the file at
     * 'src/translations.json' is used */
    languages: [
      {
        code: 'NL',
        slug: 'nl',
        name: 'Nederlands',
        locale: 'nl_NL',
      },
      {
        code: 'EN',
        slug: '',
        name: 'English',
        locale: 'en_GB',
        default: true,
      },
    ],
    cities: ['florence', 'rome', 'tivoli', 'venice', 'vicenza'],
    mediaQuery: {
      '(min-width: 1024px)': 'desktop',
      '(max-width: 767px)': 'tablet',
      '(max-width: 1024px)': 'mobile-or-tablet',
    },
  },
  /**
   * Adding plugins to this array adds them to your Gatsby site.
   *
   * Gatsby has a rich ecosystem of plugins.
   * If you need any more you can search here: https://www.gatsbyjs.com/plugins/
   */
  plugins: [
    'gatsby-plugin-sass',
    'gatsby-plugin-catch-links',

    {
      resolve: 'gatsby-plugin-transition-link',
      options: {
        layout: require.resolve('./src/components/Layout/Layout.jsx'),
      },
    },
    {
      /**
       * First up is the WordPress source plugin that connects Gatsby
       * to your WordPress site.
       *
       * visit the plugin docs to learn more
       * https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-wordpress/README.md
       *
       */
      resolve: 'gatsby-source-wordpress',
      options: {
        // the only required plugin option for WordPress is the GraphQL url.
        url: process.env.WPGRAPHQL_URL || 'http://127.0.0.1:7000/graphql',
        html: {
          useGatsbyImage: true,
          createStaticFiles: true,
        },
        production: {
          allow404Images: true,
          allow401Images: true,
        },
      },
    },

    /**
     * We need this plugin so that it adds the "File.publicURL" to our site
     * It will allow us to access static url's for assets like PDF's
     *
     * See https://www.gatsbyjs.org/packages/gatsby-source-filesystem/ for more info
     */
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'assets',
        path: `${__dirname}/content/assets`,
      },
    },

    /**
     * The following two plugins are required if you want to use Gatsby image
     * See https://www.gatsbyjs.com/docs/gatsby-image/#setting-up-gatsby-image
     * if you're curious about it.
     */
    'gatsby-plugin-image',
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',

    {
      // See https://www.gatsbyjs.com/plugins/gatsby-plugin-manifest/?=gatsby-plugin-manifest
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Italian Cities',
        short_name: 'Italian Cities',
        start_url: '/',
        background_color: '#2a2832',
        theme_color: '#29af87',
        display: 'minimal-ui',
        icon: 'content/assets/img/is-icon.png',
      },
    },

    // See https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=gatsby-plugin-react-helmet
    // 'gatsby-plugin-react-helmet',

    /**
     * this (optional) plugin enables Progressive Web App + Offline functionality
     * To learn more, visit: https://gatsby.dev/offline
     */
    // `gatsby-plugin-offline`,
  ],
};
