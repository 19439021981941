import React, { useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';

import './ToTop.scss';

const ToTop = () => {
  const { t } = useTranslation();
  const topRef = useRef(null);

  useEffect(() => {
    const toggleButton = throttle(() => {
      const newScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      if (newScroll > 300) {
        topRef.current.className = 'show';
      } else {
        topRef.current.className = '';
      }
    }, 600);

    document.addEventListener('scroll', toggleButton, false);

    return () => document.removeEventListener('scroll', toggleButton, false);
  }, []);

  return (
    <button
      ref={topRef}
      id="totop"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      type="button"
      style={{ opacity: 0 }}
    >
      <span>{t('scrolltop')}</span>
    </button>
  );
};

export default ToTop;
