import React from 'react';

import MenuEN from './MenuEN';
import MenuNL from './MenuNL';
import toggleMenu from './toggleMenu';
import './Navigation.scss';

const Menu = ({ lang }) => {
  let MenuItems = MenuEN;
  if (lang === 'NL') {
    MenuItems = MenuNL;
  }

  return (
    <nav
      id="topnav"
      className="primary-menu-wrapper"
      aria-label="Horizontal"
      role="navigation"
    >
      <div className="menu-button-wrapper">
        <button
          id="menutoggle"
          onClick={() => toggleMenu('menutoggle')}
          type="button"
          data-level={0}
          aria-expanded="false"
        >
          <span>Toggle navigation</span>
        </button>
      </div>
      <ul className="primary-menu">
        <MenuItems />
      </ul>
    </nav>
  );
};

export default Menu;
