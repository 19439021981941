import React from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n from './src/i18next';
import { PageContextProvider } from '@context/PageContext';

import { siteMetadata } from '@siteconfig';
import fontMaitreeMedium from '@content/assets/fonts/maitree-medium.woff2';
import fontMaitreeBold from '@content/assets/fonts/maitree-bold.woff2';
import fontAresenica from '@content/assets/fonts/arsenica.woff2';

const fonts = [fontMaitreeMedium, fontMaitreeBold, fontAresenica];

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  return (
    <PageContextProvider pageContext={pageContext}>
      {element}
    </PageContextProvider>
  );
};

export const onRenderBody = ({ setHeadComponents }) => {
  if (process.env.NODE_ENV !== 'production') return;
  setHeadComponents(
    fonts.map((font) => (
      <link
        key="preload"
        rel="preload"
        href={font}
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    )),
  );

  if (siteMetadata.mediaQuery) {
  }
};

/**
 * Sass automatically puts all css inline. We use this function to extract the
 * CSS into a sperate fille
 */
export const onPreRenderHTML = ({
  getHeadComponents,
  replaceHeadComponents,
}) => {
  if (process.env.NODE_ENV !== 'production') return;

  let hc = getHeadComponents();

  hc.forEach((el) => {
    if (
      el.type === 'style' &&
      el.props['data-href'] &&
      !el.props['data-href'].includes('essential')
    ) {
      el.type = 'link';
      el.props['href'] = el.props['data-href'];
      el.props['rel'] = 'stylesheet';
      el.props['type'] = 'text/css';

      delete el.props['data-href'];
      delete el.props['dangerouslySetInnerHTML'];
    }
  });

  const orderedComponents = hc.sort((item) =>
    item.key === 'preload' ? -1 : 1,
  );
  replaceHeadComponents(orderedComponents);
};
