import React from 'react';
import Link from 'gatsby-plugin-transition-link';

import { usePageContext } from '@context/PageContext';
import './LangSwitch.scss';

const LanguageSwitch = () => {
  let { language, translations } = usePageContext();
  const langItems = [];

  Object.keys(translations).forEach((lang) => {
    if (lang === language.code) {
      langItems.push(
        <li
          key={lang}
          className={`lang lang-${lang.toLocaleLowerCase()} current`}
        >
          <span>{translations[lang].name}</span>
        </li>,
      );
    } else {
      langItems.push(
        <li key={lang} className={`lang lang-${lang.toLocaleLowerCase()}`}>
          <Link
            to={translations[lang].uri}
            exit={{
              length: 0.5,
            }}
            entry={{
              length: 0.5,
            }}
          >
            {translations[lang].name}
          </Link>
        </li>,
      );
    }
  });

  return <ul className="language-switch">{langItems}</ul>;
};

export default LanguageSwitch;
