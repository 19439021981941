import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';

import { usePageContext } from '@context/PageContext';
import Logo from '@components/Logo';
import Menu from '@components/Navigation';
import FooterMenu from '@components/Navigation/FooterNav';
import LanguageSwitch from '@components/LangSwitch';
import ToTopButton from '@components/ToTop';
import './Layout.scss';

const Layout = ({ isHomePage, children }) => {
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const {
    language: { code: langcode },
  } = usePageContext();

  useEffect(() => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const toggleElements = throttle(() => {
      const newScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      if (newScroll > scrollTop) {
        menuRef.current.className = 'header-nav-wrapper hide';
      } else if (Math.abs(newScroll - scrollTop) < 60) {
        menuRef.current.className = 'header-nav-wrapper show';
      }
      scrollTop = newScroll;
    }, 500);

    const checkIsTop = () => {
      if ((window.pageYOffset || document.documentElement.scrollTop) < 60) {
        menuRef.current.className = 'header-nav-wrapper show';
      }
    };

    document.addEventListener('scroll', toggleElements, false);
    document.addEventListener('scroll', checkIsTop, false);

    return () => {
      document.removeEventListener('scroll', toggleElements, false);
      document.removeEventListener('scroll', checkIsTop, false);
    };
  }, []);

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <ToTopButton />

      <header className="global-header">
        <div ref={menuRef} className="header-nav-wrapper">
          <Logo langcode={langcode} />
          <Menu lang={langcode} />
          <LanguageSwitch />
        </div>
      </header>
      <div id="menu-backdrop" style={{ opacity: 0 }}>
        &nbsp;
      </div>

      <main id="main">{children}</main>

      <footer id="footer">
        <div className="footer-wrapper">
          <FooterMenu lang={langcode} />

          <div className="footer-msg">{t('footer')}</div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
