import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import merge from 'lodash.merge';

import { siteMetadata } from '@siteconfig';

let defaultLanguage = {};
let defaultTranslation = {};

siteMetadata.languages.forEach((lang) => {
  if (lang.default) {
    defaultLanguage = lang;
  }
  defaultTranslation[lang.code] = {
    name: lang.name,
    uri: `/${lang.slug}`,
  };
});

const PageContext = React.createContext({});

export const PageContextProvider = ({ pageContext, children }) => {
  const { i18n } = useTranslation();

  if (Array.isArray(pageContext.translations)) {
    if (pageContext.translations.length > 0) {
      let translateValue = {};
      pageContext.translations.forEach((lang) => {
        translateValue[lang.language.code] = {
          uri: lang.uri,
        };
      });
      pageContext.translations = translateValue;
    } else {
      delete pageContext.translations;
    }
  }

  const contextValue = merge(
    {},
    {
      language: defaultLanguage,
      translations: defaultTranslation,
    },
    pageContext,
  );

  useEffect(() => {
    i18n.changeLanguage(contextValue.language.code);
  }, [i18n, contextValue.language.code]);

  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};

export const usePageContext = () => React.useContext(PageContext);
