import React from 'react';

import MenuEN from './MenuEN';
import MenuNL from './MenuNL';
import './Navigation.scss';

const Menu = ({ lang }) => {
  let MenuItems = MenuEN;
  if (lang === 'NL') {
    MenuItems = MenuNL;
  }

  return (
    <nav
      id="footernav"
      className="footer-menu-wrapper"
      aria-label="Horizontal"
      role="navigation"
    >
      <ul className="footer-menu">
        <MenuItems />
      </ul>
    </nav>
  );
};

export default Menu;
