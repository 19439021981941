const isBrowser = typeof window !== 'undefined';
const windowBreakpoint = 1024;

let menuExpanded = false;
let pressedButtons = [];
let windowWidth;

if (isBrowser) {
  document.addEventListener('keydown', function(evt) {
    if (menuExpanded) {
      if (evt.isComposing || evt.keyCode === 229) {
        return;
      }
      if (evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27) {
        toggleMenu(pressedButtons[pressedButtons.length - 1]);
      }
    }
  });

  windowWidth = visualViewport.width;
  visualViewport.addEventListener('resize', () => {
    windowWidth = visualViewport.width;
    if (menuExpanded || pressedButtons.length > 0) {
      collapseAll();
      menuExpanded = false;
      toggleBackdrop();
    }
  });
}

function expand(elem) {
  const parentMenu = elem.parentNode.parentNode;
  elem.parentNode.nextElementSibling.style.display = 'flex';
  elem.setAttribute('aria-expanded', true);
  parentMenu.className += ' expanded';
  pressedButtons.push(elem);
}

function collapse(elem, skipListener = false) {
  const navigation = document.getElementById('topnav');
  const parentMenu = elem.parentNode.parentNode;
  elem.setAttribute('aria-expanded', false);
  parentMenu.className = parentMenu.className.replace(' expanded', '');

  const close = () => {
    if (parentMenu !== navigation) {
      elem.parentNode.nextElementSibling.style.display = 'none';
    } else {
      elem.parentNode.nextElementSibling.style.display = '';
    }
  };
  if (skipListener) {
    close();
  } else {
    elem.parentNode.nextElementSibling.addEventListener(
      'transitionend',
      () => close(),
      { capture: false, once: true, passive: true },
    );
  }

  pressedButtons = pressedButtons.filter((button) => button !== elem);
}

function collapseAll(level = null) {
  pressedButtons.reverse().forEach((button) => {
    if (level) {
      if (Number(button.dataset.level) === level) {
        collapse(button);
      }
    } else {
      collapse(button, true);
    }
  });
}

function menuStatus() {
  if (windowWidth > windowBreakpoint) {
    const menuCount = pressedButtons.filter(
      (button) => button.dataset.level === '1',
    );
    if (menuCount.length > 0) {
      return true;
    }
    return false;
  } else {
    const button = document.getElementById('menutoggle');
    const expanded = !(button.getAttribute('aria-expanded') !== 'true');

    return expanded;
  }
}

export function menuHide() {
  if (windowWidth > windowBreakpoint) {
    collapseAll(1);
  } else {
    toggleMenu('menutoggle');
  }
  menuExpanded = false;
  toggleBackdrop();
}

function toggleBackdrop() {
  const backdropElem = document.getElementById('menu-backdrop');
  if (menuExpanded) {
    backdropElem.className = 'active';
    backdropElem.addEventListener('click', menuHide, {
      once: true,
    });
  } else {
    backdropElem.className = '';
    backdropElem.removeEventListener('click', menuHide, {
      once: true,
    });
  }
}

export default function toggleMenu(id) {
  let button = id;
  if (typeof id === 'string') {
    button = document.getElementById(id);
  }
  const expanded = !(button.getAttribute('aria-expanded') !== 'true');
  const level = Number(button.dataset.level);

  if (expanded) {
    collapse(button);
  } else {
    if (level === 1 && windowWidth > windowBreakpoint) {
      collapseAll(1);
    }
    expand(button);
  }

  const status = menuStatus();
  if (status !== menuExpanded) {
    menuExpanded = status;
    toggleBackdrop();
  }
}
