import React from 'react';
import Link from 'gatsby-plugin-transition-link';

import toggleMenu, { menuHide } from './toggleMenu';

/*
 * The graphql results returns a flat list of menu items. Wordpress however
 * uses nested menu items. Since the graphql result is already ordered we only
 * have to loop once over the result to get a proper nested structure.
 */
export default function normalizeMenu(
  nodes,
  index = 0,
  level = 1,
  parentStack = [0],
) {
  let items = [];
  let i = index;
  while (i < nodes.length) {
    const { databaseId: id, url, label } = nodes[i];
    const nextParentId = nodes[i + 1]?.parentDatabaseId ?? 0;
    const itemId = `menu-item-${id}`;
    const buttonId = `btn-item-${id}`;
    const path = url ?? '#';

    if (nextParentId !== id) {
      items.push(
        <li id={itemId} key={id} className="menu">
          <Link
            entry={{
              length: 0.8,
            }}
            exit={{
              length: 0.8,
            }}
            to={path}
            className="item-name"
            activeClassName="item-active"
            onClick={() => menuHide()}
          >
            {label}
          </Link>
        </li>,
      );
      i += 1;

      if (nextParentId === parentStack[parentStack.length - 2]) {
        return [items, i];
      }
    } else {
      parentStack.push(id);
      const [childItems, newIndex] = normalizeMenu(
        nodes,
        i + 1,
        level + 1,
        parentStack,
      );

      let menuItem;
      if (url === null) {
        menuItem = <div className="item-name">{label}</div>;
      } else {
        menuItem = (
          <Link
            entry={{
              length: 0.8,
            }}
            exit={{
              length: 0.8,
            }}
            to={path}
            className="item-name"
            activeClassName="item-active"
            onClick={() => menuHide()}
          >
            {label}
          </Link>
        );
      }

      items.push(
        <li id={itemId} key={id} className="menu">
          <div className="item">
            {menuItem}

            <button
              onClick={() => toggleMenu(buttonId)}
              id={buttonId}
              type="button"
              data-level={level}
              aria-expanded="false"
              aria-label="Expand menu"
            >
              <span>Expand {menuItem}</span>
            </button>
          </div>
          <ul
            className={`submenu level-${level + 1}`}
            style={{ display: 'none' }}
          >
            {childItems}
          </ul>
        </li>,
      );
      parentStack.pop();
      i = newIndex;
    }
  }

  return [items, i];
}
